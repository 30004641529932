@use '../breakpoints';
@use '../colors';

.main-container-concours {
    position: relative;
    z-index: 1;
    background: white;
    box-shadow: 0px 15px 10px -15px #111;
}

.main-footer-concours{
    position: sticky;
    bottom: 0;
}

section#concours {
    width: 100%;
    height: 100%;
    padding-left: 5em;
    display: flex;
    flex-wrap: wrap;
    background-color: colors.$primary-white;
    // position: fixed;

    .left {
        width: 40%;
        height: 830px;
        border-right: colors.$primary-black 1.5px solid;

        .title {
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            border-bottom: colors.$primary-black 1.5px solid;

            h1 {
                font-size: 100px;
                font-family:"factor-a-light";
                font-feature-settings: "ss01";
            }
        }

        .img-container {
            width: 100%;
            height: 530px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 60%;
            }
        }
    }

    .post-it {
        top: 28%;
        left: 34%;
        width: 200px;
        height: 200px;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        transform: rotate(10deg);
        background-color: colors.$secondary-light-green;

        h2{
            font-size: 1.2em;
            font-family: factor-a-bold;
        }

        h1 {
            font-size: 4.6em;
            font-family: factor-a-bold;
        }
    }


    .right {
        width: 60%;
        height: 830px;
        padding-top: 6em;
        padding-left: 8em;
        padding-right: 8em;
        overflow-y: scroll;

        .formulaire-title {
            height: 100px;

            h1 {
                font-size: 60px;
                font-family: factor-a-regular;
            }
        }

        .formulaire-subtitle {
            width: 85%;
            height: 110px;

            p {
                font-size: 28px;
                line-height: 1.5;
                font-family: neue-haas-unica-pro;
            }
        }

        .formulaire-list {
            margin-bottom: 30px;

            p {
                font-size: 28px;
                line-height: 1.5;
                padding-bottom: 15px;
                font-family: neue-haas-unica-pro;
            }

            ul {
    
                li {
                    margin-left: 1em;
                    line-height: 1.5;
                    font-size: 28px;
                    font-family: neue-haas-unica-pro;
                    color: colors.$secondary-dark-purple;

                    p {
                        color: colors.$primary-black;
                    }
                }
            }
        }

        .formulaire-form {
            width: 100%;
            height: 1000px;

            .double{
                width: 100%;
                height: 120px;
                display: flex;

                .left-form {
                    padding-right: 30px;
                    width: 50%;
                }

                .right-form {
                    padding-left: 30px;
                    width: 50%;
                }
            }

            .single {
                width: 100%;
                height: 120px;
            }

            .datepicker-form{
                display: flex;
                margin-top: 30px;
                flex-direction: column;
                justify-content: center;

                .datepicker-label{

                    h1{
                        min-height: 40px;
                        font-size: 18px;
                        font-family: factor-a-bold;
                        color: colors.$primary-black;
                    }
                }
            }

            .check-zone {
                width: 100%;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-zone {
                width: 28%;
                height: 100px;                
            }

            .description-zone {
                width: 100%;
                height: 75px;

                p{
                    font-size: 15px;
                    opacity: 0.8;
                    text-align: justify;
                    color: colors.$primary-black;
                    font-family: neue-haas-unica-pro;
                }
            }
        }
    }
}


@media (max-width: breakpoints.$breakpoint-tablet) {

    section#concours {
        width: 100%;
        height: 100%;
        padding-left: 1.5em; //
        display: flex;
        flex-wrap: wrap;
        background-color: colors.$primary-white;
    
        .left {
            width: 40%;
            height: 1090px; //
            border-right: colors.$primary-black 1.5px solid;
    
            .title {
                width: 100%;
                height: 300px;
                display: flex;
                align-items: center;
                border-bottom: colors.$primary-black 1.5px solid;
    
                h1 {
                    font-size: 60px; //
                }
            }
    
            .img-container {
                width: 100%;
                height: 530px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
    
                img {
                    width: 75%; //
                }
            }
        }


        .post-it {
            top: 22%; //
            left: 26%; //
            width: 150px; //
            height: 150px; //
            display: flex;
            position: absolute;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transform: rotate(10deg);
            background-color: colors.$secondary-light-green;
    
            h2{
                font-size: 1.2em; //
                font-family: factor-a-bold;
            }
    
            h1 {
                font-size: 3.5em; //
                font-family: factor-a-bold;
            }
        }
    
    
        .right {
            width: 60%;
            height: 1090px; //
            padding-top: 6em;
            padding-left: 3.5em; //
            padding-right: 3.5em; //
            overflow-y: scroll;
    
            .formulaire-title {
                height: 100px;
    
                h1 {
                    font-size: 60px;
                    font-family: factor-a-regular;
                }
            }
    
            .formulaire-subtitle {
                width: 100%; //
                height: 150px; //
    
                p {
                    font-size: 25px; //
                    line-height: 1.5;
                    font-family: neue-haas-unica-pro;
                }
            }
    
            .formulaire-list {
                margin-bottom: 30px;
    
                p {
                    font-size: 25px; //
                    line-height: 1.5;
                    padding-bottom: 15px;
                    font-family: neue-haas-unica-pro;
                }
    
                ul {
        
                    li {
                        margin-left: 1em;
                        line-height: 1.5;
                        font-size: 25px; //
                        font-family: neue-haas-unica-pro;
                        color: colors.$secondary-dark-purple;
    
                        p {
                            color: colors.$primary-black;
                        }
                    }
                }
            }
    
            .formulaire-form {
                width: 100%;
                height: 1100px;
    
                .double{
                    width: 100%;
                    height: 120px;
                    display: flex;
    
                    .left-form {
                        padding-right: 30px;
                        width: 50%;
                    }
    
                    .right-form {
                        padding-left: 30px;
                        width: 50%;
                    }
                }
    
                .single {
                    width: 100%;
                    height: 120px;
                }
    
                .datepicker-form{
                    display: flex;
                    margin-top: 30px;
                    flex-direction: column;
                    justify-content: center;
    
                    .datepicker-label{
    
                        h1{
                            min-height: 40px;
                            font-size: 18px;
                            font-family: factor-a-bold;
                            color: colors.$primary-black;
                        }
                    }
                }
    
                .check-zone {
                    width: 100%;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    
                .button-zone {
                    width: 50%; //
                    height: 100px;  
                    margin-top: 20px; //
                }
    
                .description-zone {
                    width: 100%;
                    height: 75px;
    
                    p{
                        font-size: 15px;
                        opacity: 0.8;
                        text-align: justify;
                        color: colors.$primary-black;
                        font-family: neue-haas-unica-pro;
                    }
                }
            }
        }
    }


}
  

@media (max-width: breakpoints.$breakpoint-mobile) {

    section#concours {
        width: 100%;
        height: 100%;
        padding-left: 1.5em; //
        padding-right: 1.5em; //
        overflow-y: scroll; //

        display: flex;
        flex-wrap: wrap;
        background-color: colors.$primary-white;
    
        .left {
            width: 100%;//
            height: 520px; //
            border-right: none; //
    
            .title {
                width: 100%;
                height: 180px; //
                display: flex;
                align-items: center;
                align-content: center; //
                justify-content: center; //
                border-bottom: colors.$primary-black 1.5px solid;

                h1 {
                    font-size: 60px; //
                }
            }
    
            .img-container {
                width: 100%;
                height: 325px; //
                display: flex;
                padding-top: 30px;
                align-items: flex-start;
                justify-content: center; //
                border-bottom: colors.$primary-black 1.5px solid; //
    
                img {
                    width: 60%;
                }
            }
        }


        .post-it {
            top: 53%;
            left: 34%;
            width: 120px; //
            height: 120px; //
            display: flex;
            position: absolute;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transform: rotate(10deg);
            background-color: colors.$secondary-light-green;
    
            h2{
                font-size: 1em; //
                font-family: factor-a-bold;
            }
    
            h1 {
                font-size: 2.6em; //
                font-family: factor-a-bold;
            }
        }
    
    
        .right {
            width: 100%; //
            height: auto; //
            padding-top: 6em;
            padding-left: 1.5em; //
            padding-right: 1.5em; //
            overflow-y: hidden; //
    
            .formulaire-title {
                height: 100px;
    
                h1 {
                    font-size: 50px; //
                    font-family: factor-a-regular;
                }
            }
    
            .formulaire-subtitle {
                width: 100%; //
                height: 110px;
    
                p {
                    font-size: 17px; //
                    line-height: 1.5;
                    font-family: neue-haas-unica-pro;
                }
            }
    
            .formulaire-list {
                margin-bottom: 30px;
    
                p {
                    font-size: 17px; //
                    line-height: 1.5;
                    padding-bottom: 0px; //
                    font-family: neue-haas-unica-pro;
                }
    
                ul {
        
                    li {
                        margin-left: 1em;
                        line-height: 1.5;
                        font-size: 25px; //
                        font-family: neue-haas-unica-pro;
                        color: colors.$secondary-dark-purple;
    
                        p {
                            color: colors.$primary-black;
                        }
                    }
                }
            }
    
            .formulaire-form {
                width: 100%;
                height: 1300px; //
                display: flex;  //
                align-items: center;   //
                flex-direction: column;//
                align-content: center;//
    
                .double{
                    width: 100%;
                    height: 240px; //
                    display: flex;
                    flex-direction: column;
    
                    .left-form {
                        padding-right: 0;
                        width: 100%; //
                    }
    
                    .right-form {
                        padding-left: 0;
                        width: 100%; //
                        margin-top: 15px; //
                    }
                }
    
                .single {
                    width: 100%;
                    height: 120px;
                }
    
                .datepicker-form{
                    display: flex;
                    margin-top: 30px;
                    flex-direction: column;
                    justify-content: center;
    
                    .datepicker-label{
    
                        h1{
                            min-height: 40px;
                            font-size: 18px;
                            font-family: factor-a-bold;
                            color: colors.$primary-black;
                        }
                    }
                }
    
                .check-zone {
                    width: 100%;
                    height: 120px;
                    margin-top: 20px; //
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    
                .button-zone {
                    width: 70%;
                    height: 100px;  
                    margin-top: 40px;  //
                }
    
                .description-zone {
                    width: 100%;
                    height: 85px;
    
                    p{
                        font-size: 15px;
                        opacity: 0.8;
                        text-align: justify;
                        color: colors.$primary-black;
                        font-family: neue-haas-unica-pro;
                    }
                }
            }
        }
    }
}