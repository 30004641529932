@use '../breakpoints';
@use '../colors';

.modal-ab, .overlay {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 9999;
    

    .overlay {
        background: rgba(0, 0, 0, 0.6);
    }

    .modal-content {

        position: absolute;
        z-index: 99999999999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        padding: 14px 28px;
        max-width: 550px;
        height: 600px;
        min-width: 300px;
        background-color: colors.$primary-white;
        border: colors.$primary-black 1px solid;
        box-shadow: rgb(0, 0, 0) 15px 15px 0px -1px, rgb(255, 255, 255) 15px 15px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .quit{
            width: 100%;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            align-content: flex-end;
            text-transform: uppercase;

            h1{
                cursor: pointer;
                font-family: factor-a-bold;
                font-size: 25px;
            }
        }

        .modal-title {
            h2 {
                text-align: center;
                font-weight: bold;
                line-height: normal;
                color: colors.$primary-black;
                font-size: 42px;
                margin-left: 0.5em;
                margin-right: 0.5em;
                margin-top: 0.5em;
                margin-bottom: 1em;
            }
        }

        .modal-showcase {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 80%;
            
            img {
                transform: rotate(-10deg);
                width: 50%;
            }

        }

        .modal-choice {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin-top: 2em;
            width: 100%;

            .yes {
                width: 40%;
                height: 100px;
                text-decoration: none;
            }

            .no {
                width: 40%;
                height: 100px;
                text-decoration: none;
            }

        }

    }

}


.modal-concours, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 5;


.overlay {
    background: rgba(0, 0, 0, 0.6);
}

.modal-content {

    position: absolute;
    z-index: 9999999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 14px 28px;
    max-width: 550px;
    height: 420px;
    min-width: 300px;
    background-color: colors.$primary-white;
    border: colors.$primary-black 1px solid;
    box-shadow: rgb(0, 0, 0) 15px 15px 0px -1px, rgb(255, 255, 255) 15px 15px;

    .quit{
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        align-content: flex-end;
        text-transform: uppercase;

        h1{
            cursor: pointer;
            font-family: factor-a-bold;
            font-size: 25px;
        }
    }

    .modal-title {     
        
        display: flex;
        flex-direction: row;
        padding-top: 30px;

        h2 {
            width: 75%;
            font-weight: bold;
            line-height: normal;
            color: colors.$primary-black;
            font-size: 32px;
            margin-left: 0.5em;
            margin-right: 0.5em;
            margin-top: 0.5em;
            margin-bottom: 1em;
        }

        img{
            transform: rotate(-10deg);
            width: 25%;
        }
    }

    .modal-error-message {
        padding-left: 1em;
        padding-right: 1em;


        p {
            width: 100%;
            font-size: 18px;
            font-family: neue-haas-unica-pro;
        }

    }

    .modal-choice {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2em;
        align-items: left;
        padding-left: 1em;

        .yes {
            width: 70%;
            height: 100px;
            text-decoration: none;
        }
    }
}

}

.modal-career, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999999999999;


.overlay {
    background: rgba(0, 0, 0, 0.6);
}

.modal-content {

    position: absolute;
    z-index: 9999999999999999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 14px 28px;
    max-width: 550px;
    height: 420px;
    min-width: 300px;
    background-color: colors.$primary-white;
    border: colors.$primary-black 1px solid;
    box-shadow: rgb(0, 0, 0) 15px 15px 0px -1px, rgb(255, 255, 255) 15px 15px;

    .quit{
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        align-content: flex-end;
        text-transform: uppercase;

        h1{
            cursor: pointer;
            font-family: factor-a-bold;
            font-size: 25px;
        }
    }

    .modal-title {     
        
        display: flex;
        flex-direction: row;
        padding-top: 30px;

        h2 {
            width: 75%;
            font-weight: bold;
            line-height: normal;
            color: colors.$primary-black;
            font-size: 32px !important;
            margin-left: 0.5em;
            margin-right: 0.5em;
            margin-top: 0.5em;
            padding-bottom: 2em;
        }

        img{
            transform: rotate(-10deg);
            width: 29% !important;
            padding-bottom: 2em;

        }
    }

    .modal-error-message {
        padding-left: 1em;
        padding-right: 1em;


        p {
            width: 100%;
            font-size: 18px;
            font-family: neue-haas-unica-pro;
        }

    }

    .modal-choice {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2em;
        align-items: left;
        padding-left: 1em;

        .yes {
            width: 70%;
            height: 100px;
            text-decoration: none;
        }
    }
}

}

@media (max-width: breakpoints.$breakpoint-tablet) {

    .modal-ab,
    .overlay{

        .modal-content {

            width: 650px;
            height: 620px;
            min-width: 300px;
            padding: 0;
            padding-left: 0.5em;
            padding-right: 0.5em;

            .quit{
                padding-top: 20px;
                padding-right: 10px;
            }


            .modal-title{

                h2{
                    margin-left: 0;
                    margin-right: 0;
                    font-size: 2.3em;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .modal-showcase {


                img {
                    width: 40%;
                }

            }

            .modal-choice{

                width:100%;
                margin-top: 3em;
                padding-left: 1.5em;
                padding-right: 1.5em;

                .yes{
                    width: 100%;
                    height: 100px;
                }
                .no{
                    margin-top: 2em;
                    width: 100%;
                    height: 70px;
                }
            }
        }
    }

    .modal-career, .overlay {

        .modal-content {
        
            .modal-title {     
                flex-direction: column; ///
                padding-top: 0; ///
        
                h2 {
                    width: 100%; ///
                    font-size: 27px !important; ///
                    margin-left: 0; ///
                    margin-right: 0; ///
                    padding-bottom: 1em; ///
                }
        
                img{
                    width: 40% !important; ///
                    padding-bottom: 0; ///
                }
            }
            .modal-choice {    
                .yes {
                    width: 100%; ///
                }
            }
        }
    
    }
}


@media (max-width: breakpoints.$breakpoint-mobile) {

    .modal-ab,
    .overlay{

        .modal-content {

            width: 350px;
            height: 600px; //
            min-width: 300px;
            padding: 0;
            padding-left: 0.5em;
            padding-right: 0.5em;

            .quit{
                padding-top: 20px;
                padding-right: 10px;
            }

            .modal-title{

                h2{
                    margin-left: 0;
                    margin-right: 0;
                    font-size: 2.3em;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .modal-choice{

                width:100%;
                margin-top: 3em;
                padding-left: 1.5em;
                padding-right: 1.5em;

                .yes{
                    width: 100%;
                    height: 100px;
                }
                .no{
                    margin-top: 2em;
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }

    .modal-career, .overlay {

        .modal-content {
        
            .modal-title {     
                flex-direction: column; ///
                padding-top: 0; ///
        
                h2 {
                    width: 100%; ///
                    font-size: 27px !important; ///
                    margin-left: 0; ///
                    margin-right: 0; ///
                    padding-bottom: 1em; ///
                }
        
                img{
                    width: 40% !important; ///
                    padding-bottom: 0; ///
                }
            }
            .modal-choice {    
                .yes {
                    width: 100%; ///
                }
            }
        }
    
    }
}

